





































import { ref, defineComponent, PropType, onMounted } from '@vue/composition-api'
import { gsap } from 'gsap'
import SplitText from 'gsap/dist/SplitText.js'

import UiPromo from '@/components/ui/Promo.vue'
import UiVideoLoop from '@/components/ui/VideoLoop.vue'

import type { Picture, Link } from '@/inc/types'

interface HomeHero {
  video: string
  picture: Picture
  title: string
  link: Link
}

gsap.registerPlugin(SplitText)

export default defineComponent({
  name: 'HomeHero',
  components: {
    UiPromo,
    UiVideoLoop,
  },
  props: {
    content: {
      type: Object as PropType<HomeHero>,
      required: true,
    },
    promoLabel: {
      type: String,
      required: false,
    },
  },
  setup() {
    const hero = ref<HTMLElement | null>(null)
    const title = ref<HTMLElement | null>(null)
    const video = ref<InstanceType<typeof UiVideoLoop> | null>(null)
    const cta = ref<HTMLElement | null>(null)
    const promo = ref<InstanceType<typeof UiPromo> | null>(null)

    // Appear animation
    const startOnboard = () => {
      // Prevent animation from being triggered twice
      if (!video.value || !hero.value?.classList.contains('before-onboard')) {
        return
      }

      const tl = gsap.timeline({
        onStart: () => hero.value?.classList.remove('before-onboard'),
      })
      const splitTitle = new SplitText(title.value, {
        type: 'words',
      })

      tl.fromTo(
        video.value.rootEl,
        { clipPath: 'inset(0% 100% 0% 0%)' },
        { clipPath: 'inset(0% 0% 0% 0%)', duration: 1, ease: 'power4.inOut' }
      )
        .from(
          splitTitle.words,
          {
            duration: 2,
            opacity: 0,
            stagger: 0.05,
            y: 20,
            ease: 'power4.out',
          },
          '-=0.3'
        )
        .from(
          cta.value,
          {
            y: 10,
            opacity: 0,
            duration: 0.7,
            ease: 'power4.out',
          },
          '-=1.5'
        )

      if (promo.value) {
        tl.from(promo.value.$el, {
          opacity: 0,
          scale: 1.1,
          duration: 1.2,
          ease: 'power4.out',
        })
      }
    }

    // Start animation when picture is loaded
    // Will not be fired if picture is already complete before mounted
    const onPictureLoad = () => startOnboard()

    onMounted(() => {
      // If picture has been loaded before cpt is mounted, fire animation
      if (video.value?.picture?.isLoaded) {
        startOnboard()
      }
    })

    return {
      cta,
      hero,
      title,
      video,
      promo,
      onPictureLoad,
    }
  },
})
